import createPresignedURL2 from "../../screens/queries/createPresignedURL2";
import { uploadData } from "aws-amplify/storage";
import { generateClient } from "aws-amplify/api";
const client = generateClient();

const filetypes = {
  quicktime: { ext: "mov", type: "video" },
  mp4: { ext: "mp4", type: "video" },
  avi: { ext: "avi", type: "video" },
  flv: { ext: "flv", type: "video" },
  wmv: { ext: "wmv", type: "video" },
  mpeg: { ext: "mpeg", type: "video" },
  "3gp": { ext: "3gp", type: "video" },
  webm: { ext: "webm", type: "video" },
  mkv: { ext: "mkv", type: "video" },
  vob: { ext: "vob", type: "video" },
  ogv: { ext: "ogv", type: "video" },
  ogg: { ext: "ogg", type: "video" },
  mov: { ext: "mov", type: "video" },
  mpg: { ext: "mpg", type: "video" },
  m4v: { ext: "m4v", type: "video" },
  m2ts: { ext: "m2ts", type: "video" },
  mts: { ext: "mts", type: "video" },
  ts: { ext: "ts", type: "video" },
  mxf: { ext: "mxf", type: "video" },
  asf: { ext: "asf", type: "video" },
  m2v: { ext: "m2v", type: "video" },
  mod: { ext: "mod", type: "video" },
  tod: { ext: "tod", type: "video" },
  dat: { ext: "dat", type: "video" },
  "dvr-ms": { ext: "dvr-ms", type: "video" },
  wtv: { ext: "wtv", type: "video" },
  f4v: { ext: "f4v", type: "video" },
  rm: { ext: "rm", type: "video" },
  jpg: { ext: "jpg", type: "image" },
  jpeg: { ext: "jpeg", type: "image" },
  png: { ext: "png", type: "image" },
  gif: { ext: "gif", type: "image" },
  bmp: { ext: "bmp", type: "image" },
  tiff: { ext: "tiff", type: "image" },
  tif: { ext: "tif", type: "image" },
  webp: { ext: "webp", type: "image" },
};

const uploadImages = async (
  category,
  files,
  postID,
  path,
  callback,
  callback2
) => {
  try {
    const expectedSum = Array.from(files)?.length;
    const progressMap = {};

    Array.from(files).forEach(async (file, i) => {
      if (!file.type) return;

      const slashIndex = file.type.lastIndexOf("/");
      const fileExtension =
        filetypes[file.type.substring(slashIndex + 1)]?.ext?.toUpperCase();
      const mediaType = filetypes[file.type.substring(slashIndex + 1)]?.type;

      progressMap[i] = 0;

      // 1. Create presignedURL
      const presignedData = await client.graphql({
        query: createPresignedURL2(
          `input: {
              category: ${
                mediaType === "video" ? "POST_VIDEO" : category.toUpperCase()
              },
              type: ${fileExtension},
              imageInput: {
                subjectID: "${postID}",
                index: ${i}
              }
            }`
        ),
      });
      console.log("1. Create presignedURL", presignedData);

      // 2. Upload presigned image
      const uploadDataResult = await uploadData({
        key:
          mediaType === "video"
            ? `${
                presignedData.data.createPresignedURL.fileName
              }.${fileExtension.toLowerCase()}`
            : `/features/${path.toLowerCase()}/photos/${
                presignedData.data.createPresignedURL.fileName
              }.${fileExtension.toLowerCase()}`,
        data: file,
        options: {
          bucket: {
            bucketName:
              mediaType === "video"
                ? "video-on-demand-stack-3-source71e471f1-wajku3lq0y8m"
                : "sociogram-bucket142402-dev",
            region: "us-east-1",
          },
          isPublic: true,
          // accessLevel: 'guest',
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              progressMap[i] =
                Math.round((transferredBytes / totalBytes) * 10) * 10;
              // console.groupEnd();

              // Add up the percentages of every image upload progress
              var progressMapTotal = 0;
              Object.values(progressMap).forEach(
                (x) => (progressMapTotal += x)
              );

              console.log(progressMapTotal, expectedSum * 100);
              callback2((progressMapTotal / (expectedSum * 100)) * 100);

              if (progressMapTotal === expectedSum * 100) {
                // Acknowledge file(s) uploaded
                console.log("Uploading done. Callback...");
                callback();
              }
            }
          },
        },
      });
      console.log("2. Upload presigned URL", uploadDataResult);
    });
  } catch (error) {
    console.error("Error uploading data:", error);
  }
};

export default uploadImages;
