
const getCommentsByPost = (nextToken=null, postID=null, userID=null) =>`
query MyQuer {
  thePost:getPost(id: "${postID}") {
    createdByID
    reactionsCount
    reactions(filter: {createdByID: {eq: "${userID}"}}) {
        nextToken
        items {
        id
        type
        }
    }
    status
    text
    commentsCount
    createdAt
    id
    images {
        items {
        assetEntityID
        url
        id
        index
        }
    }
    userObject {
        ratePrivacy
        id
        phoneNumber
        givenName
        familyName
        numberOfVotes
        sumOfVotes
        images(limit: 1) {
        items {
            id
            index
            url
            }
        }
    }
    link {
        imageUrl
        title
        url
    }
    postTagUsers {
        items {
        user {
            id
            givenName
            familyName
            phoneNumber
            images(limit: 1) {
            items {
                id
                url
                index
            }
            }
        }
        }
    }
    postObject {
        createdByID
        reactionsCount
        status
        text
        commentsCount
        createdAt
        id
        activityObject {
        name
        activityEnd
        activityStart
        address
        description
        currency
        id
        locationDescription
        numberOfParticipants
        price
        privacy
        interest {
            id
            parentID
            title
            parentInterest {
            id
            title
            }
        }
        userObject {
            ratePrivacy
            id
            phoneNumber
            givenName
            familyName
            numberOfVotes
            sumOfVotes
            images(limit: 1) {
            items {
                id
                index
                url
            }
            }
        }
        activityAttendance {
            items {
            activityID
            createdByID
            status
            initStatus
            user {
                id
                familyName
                givenName
                phoneNumber
                images(limit: 1) {
                items {
                    id
                    index
                    url
                }
                }
            }
            }
        }
        }
        postTagUsers {
        items {
            user {
            id
            givenName
            familyName
            phoneNumber
            images(limit: 1) {
                items {
                id
                url
                index
                }
            }
            }
        }
        }
        userObject {
        ratePrivacy
        id
        phoneNumber
        givenName
        familyName
        numberOfVotes
        sumOfVotes
        images {
            items {
            id
            index
            url
            }
        }
        }
        link {
        imageUrl
        title
        url
        }
        images {
        items {
            assetEntityID
            url
            id
            index
        }
        }
    }
    activityObject {
        status
        paymentMethod
        createdByID
        activityEnd
        activityStart
        address
        description
        currency
        id
        locationDescription
        numberOfParticipants
        price
        privacy
        interest {
        id
        parentID
        parentInterest {
            id
            title
        }
        }
        userObject {
        ratePrivacy
        id
        phoneNumber
        givenName
        familyName
        numberOfVotes
        sumOfVotes
        images(limit: 1) {
            items {
            id
            index
            url
            }
        }
        }
        activityAttendance {
        items {
            activityID
            createdByID
            status
            initStatus
            user {
            id
            familyName
            givenName
            phoneNumber
            images(limit: 1) {
                items {
                id
                index
                url
                }
            }
            }
        }
        }
    }
  } 
  comments: getCommentsByPost(postID: "${postID}", sortDirection: ASC, limit: 10, nextToken: ${nextToken}) {
    items {
      id
      comment
      createdByID
      createdAt
      userObject {
        id
        givenName
        familyName
        phoneNumber
        numberOfVotes
        sumOfVotes
        images(limit: 1) {
          items {
            id
            index
            url
          }
        }
      }      
    }
    nextToken
  }
}`;

export default getCommentsByPost;