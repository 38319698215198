import React, { useContext, useEffect } from "react";
import { Link as ReactLink } from "react-router-dom";
import AppContext from "../../AppContext";
import {
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import getRecommendedActivities from "../screens/queries/getRecommendedActivities.js";
import { scoreRecommendations } from "../utils/recommendation-system.js";
import moment from "moment";

const RecommendedActivitiesList = () => {
  const [globalState, setGlobalState, q, p, connectionStatus] =
    useContext(AppContext);
  const [recommendations, setRecommendations] = React.useState(
    p.recommendations || {
      loading: true,
      list: [],
    }
  );

  const fetchRecommendations = async () => {
    if (!globalState.userData) return;

    var apiKey = "da2-22ztnhuamje4jptwonwzfafdi4"; // Replace 'xxx' with your actual API key
    await fetch(
      "https://3arkzxpkarhoflbprgz4tpruai.appsync-api.us-east-1.amazonaws.com/graphql",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify({
          query: getRecommendedActivities(
            globalState?.userData?.dynamoDB?.interests?.selectedInterests
          ),
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        p.recommendations = {
          list: res.data.listActivities.items,
          loading: false,
        };

        setRecommendations(p.recommendations);
      });
  };

  useEffect(() => {
    if (!globalState?.userData) return;

    fetchRecommendations();
  }, [globalState?.userData]);

  return (
    <List sx={{ padding: 0 }}>
      {!recommendations?.loading &&
        scoreRecommendations(recommendations.list)
          .slice(0, 5)
          .sort((a, b) => b.score - a.score)
          .map((obj, i) => (
            <ListItem
              key={`trending-item-${i}`}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                component={ReactLink}
                to={`/activity/${obj["id"]}`}
                sx={{
                  minHeight: 48,
                  px: 2,
                }}
              >
                <Stack direction="column">
                  <Typography variant="body2" color="textSecondary">
                    {obj["interest"].title}
                  </Typography>
                  <Box my={0.5}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      textTransform={"uppercase"}
                    >
                      {moment(obj["activityStart"]).format(
                        "DD MMMM, YYYY · hh:mm A"
                      )}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      fontWeight={"bold"}
                    >
                      {obj["name"]}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {obj["numberOfAttendances"]} participating
                  </Typography>
                </Stack>
              </ListItemButton>
            </ListItem>
          ))}

      {recommendations?.loading && (
        <Box px={2}>
          <LinearProgress sx={{ color: "#2D9CDB" }} />
        </Box>
      )}
    </List>
  );
};

export default React.memo(RecommendedActivitiesList);
