export function normalizeActivityDates(item, timestamps) {
  //   const currentTime = new Date().getTime(); // Get current date in milliseconds

  if (!item || item.length === 0) {
    return []; // Return empty array if no items
  }

  const minTime = Math.min(...timestamps);
  const maxTime = Math.max(...timestamps);

  // Normalize timestamps and update the items
  const time = new Date(item.activityStart).getTime();
  const normalizedTime = (time - minTime) / (maxTime - minTime); // Normalize between 0 and 1

  return normalizedTime;
}

export function dotProduct(array1, array2) {
  if (array1.length !== array2.length) {
    throw new Error("Arrays must be of the same length");
  }

  return array1.reduce((sum, value, index) => sum + value * array2[index], 0);
}

export function scoreRecommendations(items) {
  if (!items || items.length === 0) {
    return []; // Return empty array if no items
  }

  // Convert `activityStart` to timestamps and find min and max
  const timestamps = items.map((item) =>
    new Date(item.activityStart).getTime()
  );

  // Normalize timestamps and update the items
  const normalizedItems = items.map((item) => {
    var normalizedStart =normalizeActivityDates(item, timestamps);
    return {
      ...item,
      normalizedStart: normalizedStart,
      score: dotProduct(
        [normalizedStart, item.numberOfAttendances], 
        [10, 9]
    ),
    };
  });

  return normalizedItems;
}
