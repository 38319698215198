import React, { useState, useRef, useEffect } from "react";

const VideoThumbnail = ({ videoUrl }) => {
  const [thumbnail, setThumbnail] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  // Generate thumbnail from video
  const generateThumbnail = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Set to capture thumbnail at 25% of the video
    video.currentTime = video.duration / 4;

    // Once the video seeks, draw the image on the canvas
    video.onseeked = () => {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      setThumbnail(canvas.toDataURL()); // Save the thumbnail as a base64 image
    };
  };

  useEffect(() => {
    // Generate thumbnail once the video metadata is loaded
    if (videoUrl) {
      const video = videoRef.current;
      video.src = videoUrl;
      video.onloadedmetadata = generateThumbnail;
    }
  }, [videoUrl]);

  return (
    <div>
      <video ref={videoRef} width="400" controls style={{ display: "none" }} />

      <canvas
        ref={canvasRef}
        width="400"
        height="225"
        style={{ display: "none" }}
      />

      {thumbnail && (
        <div>
          <img src={thumbnail} alt="Video Thumbnail" width="400" />
        </div>
      )}

      {!thumbnail && <p>Loading...</p>}
    </div>
  );
};

export default VideoThumbnail;
