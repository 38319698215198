/* src/AuthenticatedRoute.js */
import React, { useContext, useEffect, useRef } from "react";
import AppContext from "./AppContext";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { signIn, getCurrentUser } from 'aws-amplify/auth';

function AuthenticatedRoute(props) {

  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const navigate = useNavigate();


  useEffect(() => {
      getCurrentUser()
      .catch(() => {
          navigate('/signin')
      });
  }, [globalState?.loggedIn])

  if (localStorage.length > 0) {
    return <Outlet globalState={globalState} />;
  } else {
    return <Navigate to="/signin" />;
  }
}
export default AuthenticatedRoute;
