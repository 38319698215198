const onSendNotification = () => `subscription MySubscription {
  onSendNotification {
    status
    updatedAt
    type
    title
    body
    createdAt
    id
    identity
    identityReceiverIDPK
    meta
    activityObject{
      id
      name
      createdAt
      activityStart
      activityEnd
      numberOfParticipants
      numberOfAttendances
    }
    postObject {
      id
      createdAt
      activityID
      text
    }
    chatObject {
      id
      channelIDCreatedByIDPK
      channelID
      channel {
        sortedP2PUserIDSPK
      }
      createdAt
      type
      content {
        text
        data
      }
    }
    contactObject {
      id
      status
      sortedUserIDSPK
    }
    activityAttendanceObject {
      user {
        familyName
        givenName
      }
      status
    }
    receiverUserID
  }
}`;

export default onSendNotification;