import React, { useContext, useState } from "react";
import {Link as ReactLink, useNavigate} from 'react-router-dom';
import {
  Box,
  Button as MuiButton,
  ButtonGroup,
  Container,
  InputAdornment,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import AppContext from "../../AppContext";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import AddHomeIcon from "@mui/icons-material/AddHome";
import EventIcon from "@mui/icons-material/Event";
import PeopleIcon from "@mui/icons-material/People";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import LinkIcon from "@mui/icons-material/Link";
import LinkInsert from "../complex/LinkInsert";

import PropTypes from "prop-types";
import { AddPeopleScreen } from "../screens/AddPeopleScreen";

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
  zIndex: 10000,
});

const LabelBox = styled(Box)(({ theme }) => ({
  // position: "fixed",
  // bottom: "56px",
  padding: theme.spacing(2),
  margin: "0 auto",
  background: "#B9DEF3",
  left: "0px",
  borderRadius: "24px 24px 0 0",
  overflow: "hidden",
}));

const AttachmentBox = styled(Box)({
  // position: "fixed",
  // bottom: "56px",
  background: "#0C2F43",
  width: "100%",
  left: "0px",
  borderRadius: "24px 24px 0 0",
  overflow: "hidden",
});

const AttachmentBoxButton = styled(MuiButton)(({ theme }) => ({
  fontSize: "1em",
  textTransform: "none",
  color: "white",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  '&.Mui-disabled' : {
    color: 'white'
  }
}));

const icons = [
  <AddToPhotosIcon />, 
  <PeopleIcon />,
  <EventIcon />,
  <AddHomeIcon />,
  <LinkIcon />,
];

const inserts = [<></>, <></>, <></>, <LinkInsert />];
const attachmentComponent = [
  "label",
  ReactLink,
  ReactLink,
  ReactLink,
  "button"
]
/**
 * Primary UI component for user interaction
 */
export const AttachmentsForPost = ({ ...props }) => {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({ tabIndex: null });
  const navigate = useNavigate();

  const setTabIndex = (event) => {
    const index = parseInt(event.currentTarget.getAttribute("index"));

    // If the tab is already opened and then clicked again, set to null
    // so as to hide the expanded section
    setState({
      ...state,
      tabIndex: index === state.tabIndex ? null : index,
    });
  };


  const disableBlockAndNavigate = (event) => {
    const goTo = event.currentTarget.getAttribute("goTo");
    p.disableBlock = true;
    navigate(goTo);
  }

  const attachmentOnClick = [
    null,
    disableBlockAndNavigate,
    disableBlockAndNavigate,
    disableBlockAndNavigate,
    setTabIndex
  ]

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          opacity: props.disabled ? 0.45 : 1,
          position: { xs: "fixed", sm: "fixed" },
          zIndex: "1000",
          bottom: { xs: 64, sm: 0 },
          width: "100%",
        }}
        maxWidth={"sm"}
      >
      {/* <Box px={2}>
        <LabelBox>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={2}
          >
            <SvgIcon sx={{ position: "relative", top: -2 }}>
              <AddHomeIcon />
            </SvgIcon>
            <Typography>Add or attach activity.</Typography>
          </Stack>
        </LabelBox>
      </Box> */}
        <AttachmentBox>
          <ButtonGroup fullWidth>
            {[
              { text: "Media" },
              { text: "People" },
              { text: "Activity"},
              { text: "Post" },
              { text: "Link" },
            ].map((obj, i) => (
              <AttachmentBoxButton
                key={`attachment-box-button-${i}`}
                disabled={props.disabled}
                index={i}
                component={attachmentComponent[i]}
                onClick={attachmentOnClick[i]}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "none",
                  "&:hover": {
                    border: "none",
                  },
                }}
                to={
                  i == 1 && "/invite" ||
                  i === 2 && "/create-post/search-activities" ||
                  i === 3 && "/create-post/search-posts"
                }
              >
                <Stack alignItems={"center"} direction="column">
                  <SvgIcon color={i === state.tabIndex ? "primary" : "white"}>
                    {icons[i]}
                  </SvgIcon>
                  <Typography
                    align="center"
                    color={i === state.tabIndex && "primary"}
                    variant="body2"
                  >
                    {obj.text}
                  </Typography>
                </Stack>
                {i === 0 && <VisuallyHiddenInput
                  type="file"
                  multiple
                  onInput={props.attachPhotoHandler}
                  onChange={props.attachPhotoHandler}
                />}
              </AttachmentBoxButton>
            ))}
          </ButtonGroup>
        </AttachmentBox>

        {state.tabIndex && (
          <Box component="section" py={2} sx={{ background: "white" }}>
            {state.tabIndex === 1 && (
              <Box px={6}>
                <AddPeopleScreen />
              </Box>
            )}
            {state.tabIndex === 4 && (
              <Box px={6}>
                <LinkInsert
                  onClickHandler={props.onAddLink}
                  callback={() => setState({ ...state, tabIndex: null })}
                />
              </Box>
            )}
            {/* {inserts[state.tabIndex]} */}
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

AttachmentsForPost.propTypes = {};

AttachmentsForPost.defaultProps = {
  color: "primary",
};
