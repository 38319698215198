const getRecommendedActivities = (intrerests) => {

  return `
  query getRecommendedActivities {
  listActivities(
    filter: {
      activityStart: {ge: "2024-11-01T12:00:00.303+01:00"}, 
      numberOfParticipants: {ge: 5}, 
      numberOfAttendances: {ge: 1}, 
      address: {contains: "Dubai"}, 
      or: [
      ${
        intrerests
        .map(
          (id)=>`{interestID: {ne:"${id}"}}`
        ).join(",")
      }
      ]
    }
  ) {
    items {
      id
      activityStart
      name
      address
      numberOfAttendances
      userObject {
        contacts {
          items {
            id
          }
        }
      }
      interest {
        title
        id
      }
    }
  }
}`;
};

export default getRecommendedActivities;
