import React, { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactLink } from "react-router-dom";
import AppContext from "../../AppContext";
import {
  Avatar,
  Box,
  Button as MuiButton,
  Container,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes, { object } from "prop-types";
import Search from "@mui/icons-material/Search";
import Fuse from "fuse.js";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { AvatarLink } from "../basic/AvatarLink.jsx";
import { Button } from "../basic/Button";
import { ScreenNavHeader } from "../complex/ScreenNavHeader";
import { SearchTextField } from "../basic/SearchTextField";
import AddIcon from "@mui/icons-material/Add";
import { ResponsiveLayoutSidebar } from "../complex/ResponsiveLayoutSidebar";
import contactsByUserID from "./queries/contactsByUserID.js";
import {
  getChatChannelBySortedP2PUserIDS_array,
  getContactObjects,
} from "./queries/getChatChannelBySortedP2PUserIDS.js";
import { generateClient } from "aws-amplify/api";
const client = generateClient();

const theme = createTheme({
  palette: {},
  components: {
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //     },
    //   },
    // },
  },
});

const contactStatusMap = {
  REQUESTED: "Invitation sent",
  ACCEPTED: "Contact",
};
/**
 * Primary UI component for user interaction
 */
export const MessagesScreen = ({ ...props }) => {
  const [globalState, setGlobalState, q, p] = useContext(AppContext);
  const [state, setState] = useState({
    data: [],
    nextToken: null,
    loading: true,
  });
  const timeoutId = useRef(null);
  const fuse = useRef();
  const originalData = useRef();

  const fetchData = async () => {
    try {
      const res = await client.graphql({
        // query: contactsByUserID("9d1d5590-7b65-4e09-8bce-64035ecfbed5")
        query: getChatChannelBySortedP2PUserIDS_array(
          globalState?.userData?.userId,
          globalState?.userData?.["dynamoDB"]?.contacts?.items
        ),
      });

      const res2 = await client.graphql({
        query: getContactObjects(
          globalState?.userData?.["dynamoDB"]?.contacts?.items
        ),
      });

      const dataItems = Object.values(res.data);

      const contactItems = {};
      Object.values(res2.data).forEach((obj) => {
        contactItems[obj?.contactID] = obj;
      });

      // var messagesData = [];
      const messagesData = dataItems
        ?.map((obj) => {
          if (!obj) return false;
          return {
            ...obj,
            thisUser: obj?.chatChannelMembers?.items?.filter(
              (member) => member?.userID === globalState?.userData?.userId
            )[0],
            otherUser: obj?.chatChannelMembers?.items?.filter(
              (member) => member?.userID !== globalState?.userData?.userId
            )[0],
          };
        })
        ?.filter((obj) => obj);

      originalData.current = [...messagesData];
      
      setState({
        ...state,
        data: [...messagesData],
        contactItems: contactItems,
        nextToken: null, //res.data.contactsByUserID.nextToken,
        loading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    (async () => {
      if (!globalState?.userData?.userId) return;
      fetchData();
    })();
  }, [globalState?.userData]);

  const searchContacts = async (event) => {
    const keyword = event.currentTarget.value;

    // Clear previous search invocation if called prior to timeout
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    // Set new timeout id
    const newTimeoutId = setTimeout(async () => {
      if (!keyword) {
        setState({ ...state, data: originalData.current });
        return;
      } else {
        setState({
          ...state,
          data: new Fuse(originalData.current, {
            threshold: 0.7,
            keys: ["contact.givenName", "contact.familyName"],
          })
            .search(keyword)
            .map((obj) => {
              return { ...obj.item };
            }),
        });
      }
    }, [500]);

    // Set the timeout id in state
    timeoutId.current = newTimeoutId;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={"sm"}>
        <Box mb={0}>
          <ScreenNavHeader title="Messages" />
        </Box>

        <Box mb={2.5}>
          <SearchTextField
            fullWidth={true}
            size="medium"
            placeholder="Search"
            sx={{ borderRadius: "100%" }}
            onChange={searchContacts}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="Search" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box>
          {!state.loading &&
            state.data
              .sort((a, b) => new Date(b.latestMessageAt) - new Date(a.latestMessageAt))
              .map((msgObj, i) => (
                <Stack
                  key={`comment-${i}`}
                  direction={"row"}
                  spacing={2}
                  alignItems={"flex-start"}
                  mb={4}
                >
                  <AvatarLink
                    to={`/profile/${msgObj?.otherUser?.user?.id}`}
                    sx={{ width: 48, height: 48 }}
                    src={msgObj?.otherUser?.user?.images?.items?.[0]?.url}
                  />
                  <Stack direction={"column"} sx={{ flexGrow: 1 }}>
                    <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                      <ReactLink
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={`/profile/${msgObj?.otherUser?.user?.id}`}
                      >
                        <Typography variant="body1">
                          {msgObj?.otherUser?.user?.givenName}{" "}
                          {msgObj?.otherUser?.user?.familyName}
                        </Typography>
                      </ReactLink>
                      <Typography variant="body2" color={"textSecondary"}>
                        {" "}
                        ·{" "}
                      </Typography>
                      <Typography variant="body2" color={"textSecondary"}>
                        {
                          contactStatusMap[
                            state?.contactItems?.[msgObj?.otherUser?.user?.id]
                              ?.status
                          ]
                        }
                      </Typography>
                    </Stack>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      fontStyle={
                        msgObj?.latestMessage?.content?.text ? "italic" : "normal"
                      }
                    >
                      {msgObj?.latestMessage?.content?.text &&
                        (msgObj?.latestMessage?.content?.text.length > 50
                          ? `${msgObj?.latestMessage?.content?.text.substring(0, 50)}...`
                          : msgObj?.latestMessage?.content?.text)}
                    </Typography>
                  </Stack>
                  <IconButton
                    component={ReactLink}
                    to={`/chat/${state?.contactItems?.[
                      msgObj?.otherUser?.user?.id
                    ]?.sortedUserIDSPK?.replace("#", ",")}`}
                  >
                    <SvgIcon>
                      <ChatBubbleIcon />
                    </SvgIcon>
                  </IconButton>
                </Stack>
              ))}

          {state.loading &&
            state.data.length === 0 &&
            [1].map((v, i) => (
              <Stack
                key={`skeleton-activity-${i}`}
                direction={"column"}
                spacing={1}
                mb={4}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <Skeleton
                    animation={"wave"}
                    variant="circular"
                    width={48}
                    height={48}
                  />
                  <Stack
                    direction={"row"}
                    spacing={1}
                    flexGrow={1}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"column"} spacing={1}>
                      <Skeleton variant="rounded" width={160} height={10} />
                      <Skeleton
                        variant="rounded"
                        width={100}
                        height={10}
                        sx={{ flexGrow: 1 }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            ))}

          {!state.loading && state.data.length === 0 && (
            <Stack
              mt={4}
              direction={"column"}
              alignItems={"center"}
              spacing={2}
            >
              <Typography align="center" variant="h6" fontWeight={700}>
                No contacts yet.
              </Typography>
              <Typography variant="body1">
                Click on "Invite" to invite your friends to Sociogram.
              </Typography>
            </Stack>
          )}
        </Box>

        {/* <AppDialog open={true}>
          <Avatar>S</Avatar>
          <Stack>
            <Button>Send a Message</Button>
          </Stack>
        </AppDialog> */}
      </Container>
    </ThemeProvider>
  );
};

export const MessagesScreenLayout = (props) => {
  return (
    <ResponsiveLayoutSidebar waitForUserData={props.waitForUserData}>
      <MessagesScreen {...props} />
    </ResponsiveLayoutSidebar>
  );
};

MessagesScreen.propTypes = {};

MessagesScreen.defaultProps = {
  color: "primary",
};
