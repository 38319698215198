import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

const HlsVideoPlayer = ({ videoSource }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (Hls.isSupported() && videoRef.current) {
      const hls = new Hls();
      hls.loadSource(videoSource);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        // videoRef.current.play();
      });
      return () => {
        hls.destroy();
      };
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = videoSource;
      videoRef.current.addEventListener('loadedmetadata', () => {
        // videoRef.current.play();
      });
    }
  }, [videoSource]);

  return (
    <video
      ref={videoRef}
      controls
      style={{ width: '100%', maxWidth: '720px' }}
    />
  );
};

export default HlsVideoPlayer;
